@import "../../variables";

.MyAccount {
    .mainGrid {
        background-color: white;
        margin: auto;
        width: unset;
        padding: 15px;
        border-radius: 1rem;
        max-width: 650px;
    
        .MuiGrid-item {
          padding: unset;
          padding: 25px 0;
        }
    
        .MuiCollapse-root {
          width: 100%;
        }
      }

    .PWDiv {
        padding: 5px;
        margin-top: 25px;
        border-radius: 1rem;
        background-color: $grey2;
    }
}