.BasicAlertContainer {
  position: fixed;
  bottom: 25px;
  right: 0;
  left: 0;

  .alert {
    margin: auto;
    max-width: 400px;
    color: black;
  }
}

.BasicToastContainer {
  position: fixed;
  top: 25px;
  left: 10px;

  .alert {
    margin: auto;
    max-width: 400px;
    color: black;
  }
}
