@import "variables";

.App {
  background-color: $grey;
  //min-height: 100vh;
}

.BasicPage {
  padding: 20px;
  background-color: white;

  position: fixed;
  top: 74px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border-radius: 1rem;
  overflow: auto;
}

.SmallShadow {
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  transform: scale(1);
}

.socketDiv {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 9999;
  border-radius: 1rem;

  .hiddenSocket {
    opacity: 0;
  }

  .showSocket {
    opacity: 100;
    background-color: #f4f4f4;
    border: 1px solid black;
    border-radius: 1rem;
    padding: 5px;
  }
}

.socketDiv:hover {
  opacity: 100;
  background-color: #f4f4f4;
  border: 1px solid black;
  border-radius: 1rem;
  padding: 5px;
}

.Disable-text-selection {
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.BasicHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid black;
}

.Page {
  position: fixed;
  top: $appbar_height;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

.MainContainer {
  background-color: rgba(92, 88, 88, 0.6);
  min-height: calc(100vh - $appbar_height - 50px);

  margin: 25px;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  row-gap: 25px;

  .div1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1;

    background-color: rgba(44, 43, 43, 0.9);
    border-radius: 1rem 1rem 0 0;
    color: #f4f4f4;
  }
  .div2 {
    padding: 15px;
    flex: 2;
  }
}

// CARDS **********************************************

.Card-item {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-size: 22px;
  }

  p {
    flex: 1;
  }
}

.Card-item:hover {
  background-color: $grey1;
  color: white;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;

  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;

  cursor: pointer;
  transform: scale(1.02);
  -webkit-transition: transform 0.2s ease-out !important;
  -moz-transition: transform 0.2s ease-out !important;
  -o-transition: transform 0.2s ease-out !important;
  transition: transform 0.2s ease-out !important;
}

.CardShadow {
  -webkit-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.33);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.33);
  transform: scale(1);
  border-radius: 1rem;
}

@media screen and (max-width: 650px) {
  .BasicPage {
    padding: 10px;
    left: 0px;
    right: 0px;
  }

  .Card-item {
    background-color: #f4f4f4;
    padding: 0 10px;
    border-radius: 1rem;

    p {
      font-size: 12px;
    }
  }

  .MainContainer {
    margin: 5px;

    .div2 {
      padding: 50px 5px;
    }
  }
}
