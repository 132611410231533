@import "../../variables";


.Upload {
    display: flex;
    align-items: center;
    height: 50px;

    .progressDiv {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }
}