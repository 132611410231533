@import "../../variables";

.BasicCard {
  border: 1px solid black;
  padding: 5px;
  border-radius: 1rem;

  p {
    margin: 0;
  }

  .innerCard {
    display: flex;
    flex-direction: column;
  }

  .p-title {
    font-size: 3vmax;
  }

  .p-date {
    font-size: 3vmin;
    text-align: center;
  }
  .p-time {
    font-size: 7vmin;
    text-align: center;

    color: $gold;
  }

  .header {
    flex: 1;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content {
    height: 300px;
    padding: 15px 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    video {
      max-height: 100%;
      max-width: 100%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .footer {
    flex: 1;
    border-top: 1px solid grey;
    padding: 5px;
  }

  .hoverIconEffect:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.3s ease-out !important;
    background-color: $grey;
    padding: 5px;
    border-radius: 50%;
  }
}

.BasicCard:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition: transform 0.3s ease-out !important;
  border: 2px solid $gold;

  .hoverIconEffect {
    color: red;
  }
}

.CommonCard {
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 1rem;
  min-height: 150px;

  display: flex;
  flex-direction: column;

  .header {
    border-radius: 1rem 1rem 0 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: rgba(44, 43, 43, 0.9);
    color: #f4f4f4;
    padding: 0 5px;
  }
  .content {
    flex: 1;
    background-color: $grey2;
    min-height: 100px;
  }
  .footer {
    padding: 10px 5px;
    border-radius: 0 0 1rem 1rem;
    background-color: $grey2;

    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;

    .f-div1 {
      white-space: nowrap;
      flex: 1;

      display: flex;
      justify-content: space-evenly;
    }
    .f-div2 {
      white-space: nowrap;
      flex: 1;

      display: flex;
      justify-content: space-evenly;
    }
  }
}

.DisplayCard {
  .header {
    column-gap: 15px;
    .header-center {
      flex: 1;
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px !important;
    border-bottom: 1px solid black;
    p {
      font-size: 22px;
    }

    .timestampDiv {
      p {
        font-size: 14px;
      }
      
      .alert {
        color: red;
      }

      .ok {
        color: $lightGreen;
      }

      li {
        margin: 10px 0;
      }

      .allTimesDiv {
        border-top: 1px solid black;
        //margin: 0 -25px;
        max-height: 300px;
        overflow: auto;
        padding: 15px 5px;
      }
    }
  }

  .timestampsDiv {
    width: 100%;
    font-size: 12px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .row1 {
      display: flex;
      align-items: center;
      column-gap: 5px;
      padding: 5px;
    }

   
  }

  .statusBox {
    padding: 15px 0;
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 5px;

    p {
      margin: 0;
    }
  }

  .footer {
    flex-direction: column;
    padding: 0;

    li {
      white-space: pre-wrap;
      padding: 5px 0;
    }
  }

  .dot {
    height: 20px;
    width: 20px;
    border-radius: 1rem;
  }

  .dot-online {
    @extend .dot;
    background-color: green;
  }

  .dot-offline {
    @extend .dot;

    background-color: red;
  }
}

.LayoutCard {
  flex: 1 1 42%;
  width: 95%;
  max-width: 770px;
  //min-height: 150px;
  border-radius: 0.7rem;
  background-color: white;

  display: flex;
  flex-direction: column;
  padding: 15px;
  max-width: 650px;
  min-width: 330px;

  p {
    margin: 0;
  }

  .innerDiv {
    min-height: 250px;
  }

  .header {
    border-radius: 0.7rem 0.7rem 0 0;
    margin: -15px -15px 0 -15px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: rgba(44, 43, 43, 0.9);
    color: #f4f4f4;
    padding: 5px;

    h2 {
      text-align: center;
      width: 100%;
    }
  }

  .content {
    padding: 7px;
    background-color: white;
    min-height: unset;

    p {
      padding: 5px;
    }
  }

  .timer-content {
    .row1 {
      padding: 5px 0;
    }

    .row2 {
      padding: 15px 0;
    }

    .weekDayBtn {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .repeatDiv {
      background-color: $grey2;
      padding: 15px 5px;
      margin: 0 -20px;
    }
    .weekdaysChecks {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .buttonRow {
    padding: 25px;
    display: flex;
    justify-content: space-between;
    column-gap: 15px;

    background-color: white;

    button {
      flex: 1;
    }
  }

  .footer {
    padding-top: 15px;
    display: flex;
    justify-content: center;
    background-color: white;
  }

  .footer-active {
    button {
      background-color: $lightGreen;
    }
  }
}

.activeDp {
  .MuiTypography-root {
    color: $lightGreen !important;
  }
}

.MuiPopover-paper {
  padding: 15px;
  .popoverMain {
    display: flex;
    flex-direction: column;
  }
}

.activeSelection {
  border: 3px solid $gold !important;
}

.AddItemCard {
  width: 90%;
  max-width: 550px;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 1rem 1rem;
    background-color: $lightGreen;

    .MuiSvgIcon-root {
      font-size: 50px;
    }
  }
}

.ActiveCard {
  position: fixed;
  top: 0;
  bottom: 0px;
  right: 0;
  left: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 30px;
  z-index: 999;
  overflow: auto;
  height: unset;

  background-color: rgba(140, 137, 137, 0.93);

  .dot {
    height: 20px;
    width: 20px;
    border-radius: 1rem;
  }

  .dot-online {
    @extend .dot;
    background-color: green;
  }

  .dot-offline {
    @extend .dot;

    background-color: red;
  }

  .statusBox {
    padding: 15px 0;
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 5px;

    p {
      margin: 0;
    }
  }

  .mainDiv {
    background-color: rgba(252, 250, 250, 0.93);
    border-radius: 1rem;
    min-height: calc(100% - 40px);

    padding: 15px;
  }

  .topPart {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    text-align: center;
  }

  .helpTextBox {
    font-size: 18px;
    text-align: center;
    padding: 35px 0;
  }
  .statusBox {
    flex: unset;
  }
  .layouts {
    display: flex;
    column-gap: 15px;
    overflow-x: auto;
    padding: 50px 15px;
    background-color: $grey2;
  }
}
