@import "../../variables";

.Appbar {
  background-color: $appbarBack !important;
  color: white !important;

  img {
    height: calc($appbar_height - 4px);
    width: calc($appbar_height - 4px);

    border-radius: 50%;
  }
}
