@import "../../variables";
.ConfirmModal {
  .modalBox {
    .textDiv {
      padding-bottom: 50px;
    }

    .buttonDiv {
      display: flex;
      justify-content: center;
      column-gap: 25px;
    }
  }
}

.BasicStepperModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(44, 43, 43, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .rootDiv {
    padding: 25px;
    width: 90%;
    max-width: 950px;

    overflow: auto;
    max-height: calc(100vh - 50px);
 
  }

  .innerRoot {
    border-radius: 1rem;
    background-color: #ffffff;
    //  min-height: calc(100vh - 50px);
    min-height: 500px;
    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
  }

  .contentRoot {
    flex: 1;

    display: flex;
    flex-direction: column;

    .stepperDiv {
      padding: 5px 0;
      position: sticky;
      top: -25px;
      background-color: white;
    }
  }

  .content {
    flex: 1;

    border-top: 1px solid black;
    padding: 15px;

    .modal-content {
      .MuiFormControl-root {
        width: 100%;
      }
    }

    .rotationSelectRoot {
    }

    .rotationSelectDiv {
      display: flex;
      column-gap: 25px;

      .box {
        height: inherit;
        background-color: lightgray;
        height: 200px;
        flex: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
      }

      .activeBox {
        border: 3px solid $gold;
      }
    }
  }

  .footer {
    padding: 25px;
    text-align: right;
  }
}
