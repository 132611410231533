.ThreeCardsCol {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ThreeCards {
  .MuiCard-root {
    max-width: unset;
  }
}