@import "../../variables";

.SelectLayoutType {
  background-color: $boxDivBack;
  margin: 0 -15px;
  padding: 15px;
  height: 100%;
  
  //overflow: auto;



  .buttonsDiv {
    padding: 25px 0;
    overflow: auto;
  }

  .buttonBox {
    border: 1px solid black;
    padding: 10px;
    background-color: white;
    border-radius: 1rem;

    .innerBox {
      background-color: $boxBack;
      height: 100%;
      flex: 1;
      border-radius: 1rem;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .buttonBox-0 {
      @extend .buttonBox;
      height: 141px;
      width: 250px;
      margin: auto;
    }

    .buttonBox-90 {
      @extend .buttonBox;

      height: 250px;
      width: 141px;
      margin: auto;
    }

    .sideBySide {
      display: flex;
      column-gap: 15px;
    }

    .upDown {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }

    .grid2x2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }
  }

  .flex {
    display: flex;
    column-gap: 5px;
    overflow-x: auto;

    .buttonBox-0 {
      @extend .buttonBox;
      flex: 0 0 250px;

      height: 141px;
    }

    .buttonBox-90 {
      flex: 0 0 141px;
      height: 250px;

      @extend .buttonBox;
    }

    .sideBySide {
      display: flex;
      column-gap: 5px;
    }

    .upDown {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .grid2x2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 5px;
      grid-row-gap: 5px;
    }
  }

  .activeLayout {
    border: 5px solid $gold !important;
  }
}

@media screen and (max-width: 650px) {
  .SelectLayoutType {

 
    .buttonBox-90 {
      height: 150px !important;
      width: 84px !important;
    }

    .sideBySide {
      display: flex;
      column-gap: 5px !important;
    }

    .upDown {
      display: flex;
      flex-direction: column;
      row-gap: 5px !important;
    }

    .grid2x2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 5px !important;
      grid-row-gap: 5px !important;
    }
  }
}
