@import "../../variables";

.NoItems {
  background-color: white;
  text-align: center;

  padding: 25px;
  border-radius: 1rem;

  .childrenDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -25px 0 -25px;
  }
}
