@import "../../variables";

.OneLayout {
  .div1 {
    .div1-left {
      .MuiFormControl-root {
        background-color: white;
      }
    }
  }
  .div2 {
    background-color: white;
  }

  .MuiAccordion-root {
    background-color: $boxDivBack;
    padding: 15px 0;
    border: 2px solid black;
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    column-gap: 15px;

    .accordionDiv1 {
      display: flex;
      align-items: center;
      flex-direction: column;

      .MuiFormControl-root {
        max-width: 100px;
        text-align: center;
      }
      
    }

    p {
      text-align: center;
      flex: 1;
    }
  }

  .accordionsDiv {
    padding: 25px 15px;
    max-width: 950px;
    margin: auto;

    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .MuiAccordionDetails-root {
    background-color: white;
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .accorditonDetailsRoot {
    padding: 15px 0;
  }

  .accordionDiv1-header {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    p {
      font-size: 26px;
    }
  }

  .accordionDiv1-content {
    .content-files {
      display: flex;
      row-gap: 25px;
      column-gap: 25px;

      overflow-x: auto;
    }

    .emptyDiv {
      padding: 15px 0;

      .confirmDiv {
        color: red;
        padding: 25px;
      }
    }
  }

  .imgDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    row-gap: 10px;
    border: 1px solid black;
    padding: 15px;
    border-radius: 1rem;

    img {
      //flex: 1;
      max-width: 100%;
      max-height: 100%;
      min-width: 250px;
      border-radius: 1rem;
    }

    video {
      max-width: 100%;
      max-height: 300px;
      min-width: 350px;
      border-radius: 1rem;
    }

    .imgContent {
      flex: 1;

      display: flex;
      align-items: center;
    }

    .imgFooter {
      background-color: white;
      justify-self: flex-end;
    }
  }

  .activeImg {
    background-color: $gold;
  }

  .content-options {
    padding: 15px 0;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .optionsDiv {
    max-width: 450px;
    background-color: $grey2;
    padding: 15px;
    border-radius: 1rem;
    margin-top: 25px;
  }

  .accordionDiv-row {
    padding: 15px;
    border-radius: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .delButtonDiv {
      flex: 1;
      text-align: right;
    }

    .settingsDiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 25px;
    }
  }
}

.CardIndexImg {
  height: 100px;

  img {
    max-height: 100%;
  }
}
@media screen and (max-width: 650px) {
  .accorditonDetailsRoot {
    padding-left: 0 !important;
  }

  .MuiAccordionSummary-content {
    flex-direction: column-reverse;
    align-items: center;
  }
}
