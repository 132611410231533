.NotificationsContainer {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 10px;

  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.Notification {
  background-color: rgba(212, 175, 55, 0.8);
  border: 5px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  padding: 10px;
  border-radius: 1rem;

  p {
    margin: 0;
  }

  .p-date {
    text-align: left;
  }

  .p-title {
    font-weight: bold;
    font-size: 52px;
  }

  .p-desc {
    font-size: 32px;
    font-weight: lighter;
  }

  .circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    // background-color: orange;
    padding: 5px;

    img {
      width: 100%;
    }
  }
}
