$gold: #d4af37;
$grey: #464543;

$db_padding: 20 + px;
$card_padding: 10 + px;

$appbar_height: 58 + px;

$grey1: #424543;
$grey2: #d4d6d9;
$appbarBack: #222224;
$primary: #b39c59;

$lightGreen: #7ebf85;

$boxBack: #3a3a3a;
$boxDivBack: #d9d9d9;

$onlyWinfo: #96ae09;
$winfoDanger: #ffc300;
