@import "../../variables";

.Register {
  .mainGrid {
    background-color: white;
    margin: auto;
    width: unset;
    padding: 15px;
    border-radius: 1rem;
    max-width: 650px;

    .MuiGrid-item {
      padding: unset;
      padding: 25px 0;
    }

    .MuiCollapse-root {
      width: 100%;
    }
  }
}

.FindUser {
  .mainGrid {
    background-color: white;
    margin: 15px auto;
    width: unset;
    padding: 15px;
    border-radius: 1rem;
    max-width: 90%;

    position: relative;

    .refresIcon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .MuiCollapse-root {
    width: calc(100% - 30px);
  }

  .collapseDiv {
    display: flex;
    justify-content: center;
    padding: 50px 15px 0 15px;
    width: 100%;
  }
}

.UserCard {
  border: 1px solid $grey;
  border-radius: 1rem;

  p {
    margin: 0;
  }

  .header {
    background-color: $grey2;
    border-radius: 1rem 1rem 0 0;
    padding: 10px;
  }
}

.OneUser {
  .mainGrid {
    background-color: white;
    margin: auto;
    width: unset;
    padding: 15px;
    border-radius: 1rem;
    max-width: 650px;

    .MuiGrid-item {
      padding: unset;
      padding: 25px 0;
    }

    .MuiCollapse-root {
      width: 100%;
    }
  }
}

.LinkDBtoDeviceId {
  padding: 25px 15px;
  min-height: calc(100vh - $appbar_height - 50px);
  display: flex;
  flex-direction: column;
  background-color: $grey;

  h1 {
    margin: 0;
  }

  .headerDiv {
    background-color: white;
    padding: 50px 25px;
    border-radius: 1rem;
  }

  .contentDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 0;

  //  justify-content: center;
    align-items: center;

    form {
      background-color: white;
      width: 80%;
      max-width: 650px;
      padding: 50px 25px;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      border-radius: 1rem;

      button {
        margin-top: 50px;
      }
    }
  }
}
